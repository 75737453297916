import '../scss/frontend.scss';
import Popup from './parts/popup-window';
function ready() {
    const voiceInput = document.querySelector('.js-search-input');
    jQuery(document).ready(function ($) {
        let postId = null;
        const isExpired30 = document.body.getAttribute('data-expired-30-days') === '1';
        const isExpired3 = document.body.getAttribute('data-expired-3-days') === '1';
        document.body.classList.forEach(function (className) {
            if (className.startsWith('postid-')) {
                postId = className.replace('postid-', '');
            }
        });
        const storedData = localStorage.getItem('viewed_posts');
        let viewedPosts = storedData ? JSON.parse(storedData) : {
            "30_days": {},
            "3_days": {},
        };
        document.body.addEventListener('click', (e) => {
            const target = e.target;
            const { role } = target.dataset;
            if (!role)
                return;
            switch (role) {
                default:
                    break;
            }
        });
        function resetViewCounts() {
            if (isExpired30) {
                viewedPosts["30_days"] = {};
                viewedPosts.resetTime30 = 0;
            }
            if (isExpired3) {
                viewedPosts["3_days"] = {};
                viewedPosts.resetTime3 = 0;
            }
            localStorage.setItem('viewed_posts', JSON.stringify(viewedPosts));
        }
        function updatePostViews(updateType, postId) {
            if (!updateType)
                return;
            const data = new FormData();
            data.append('action', 'update_post_views');
            data.append('post_id', postId);
            data.append('update_type', updateType);
            fetch(ajax_object.ajaxurl, {
                method: 'POST',
                body: data
            })
                .then(response => response.json())
                .then(data => {
                if (data.success) {
                    if (updateType === '30day') {
                        viewedPosts["30_days"][postId] = true;
                    }
                    else if (updateType === '3day') {
                        viewedPosts["3_days"][postId] = true;
                    }
                    localStorage.setItem('viewed_posts', JSON.stringify(viewedPosts));
                }
            })
                .catch(error => console.error('Error update post data:', error));
        }
        resetViewCounts();
        if (document.body.classList.contains('single')) {
            if (!postId)
                return;
            const isViewed30 = viewedPosts["30_days"].hasOwnProperty(postId);
            const isViewed3 = viewedPosts["3_days"].hasOwnProperty(postId);
            if (!isViewed30) {
                updatePostViews('30day', postId);
            }
            if (!isViewed3) {
                updatePostViews('3day', postId);
            }
        }
        const initWelcomePopup = () => {
            const welcomePopup = document.querySelector('#welcome-popup');
            const popupInstance = new Popup();
            if (!welcomePopup)
                return;
            const halfYearInMs = 1000 * 60 * 60 * 24 * 30 * 6;
            const popupKey = 'welcome-popup';
            const setPopupInLocalStorage = () => {
                const expirationTime = Date.now() + halfYearInMs;
                localStorage.setItem(popupKey, expirationTime.toString());
            };
            const shouldShowPopup = () => {
                const storedTime = localStorage.getItem(popupKey);
                if (!storedTime) {
                    return true;
                }
                const expirationTime = parseInt(storedTime, 10);
                if (Date.now() > expirationTime) {
                    localStorage.removeItem(popupKey);
                    return true;
                }
                return false;
            };
            if (shouldShowPopup()) {
                const welcomePopupShowTime = window.document.body.dataset.welcomePopup;
                popupInstance.init();
                popupInstance.openOnePopup('#welcome-popup', welcomePopupShowTime ? +welcomePopupShowTime : 3000);
                setPopupInLocalStorage();
            }
        };
        initWelcomePopup();
        const openMobileNavMenu = () => {
            const navMenuItems = document.querySelectorAll('.js-header-nav-bar .menu-item-has-children a');
            if ([...navMenuItems].length < 0)
                return;
            navMenuItems.forEach(item => {
                item.addEventListener('click', (event) => {
                    if (window.innerWidth <= 480) {
                        const isParentCustomToggle = item.closest('.customToggle');
                        const parentEl = item.closest('.menu-item-has-children');
                        const mainWrapper = document.querySelector('.js-main-wrapper');
                        const menuItemHasChildrenArr = document.querySelectorAll('.menu-item-has-children');
                        const targetSubMenu = parentEl
                            ? parentEl.querySelector('.sub-menu')
                            : null;
                        if (targetSubMenu && parentEl && mainWrapper && !isParentCustomToggle) {
                            const actionType = parentEl.classList.contains('opened-sub-menu') ? 'remove' : 'add';
                            [...menuItemHasChildrenArr].forEach(item => {
                                item.classList.remove('opened-sub-menu');
                            });
                            parentEl.classList[actionType]('opened-sub-menu');
                            mainWrapper.classList[actionType]('opened-sub-menu');
                            document.body.classList[actionType]('opened-sub-menu');
                        }
                    }
                });
            });
        };
        openMobileNavMenu();
        const fetchCoinGeckoData = () => {
            const bodyClass = document.body.className;
            const match = bodyClass.match(/page-id-(\d+)/);
            if (typeof window.marketCapStartIndex === 'undefined' || window.marketCapStartIndex === null)
                return;
            const data = new FormData();
            data.append('action', 'get_market_data');
            data.append('startIndex', marketCapStartIndex);
            data.append('nonce', ajax_object.nonce);
            if (marketCapItemsPerPage) {
                data.append('itemsPerPage', marketCapItemsPerPage);
            }
            if (match) {
                data.append('pageId', match[1]);
            }
            fetch(ajax_object.ajaxurl, {
                method: 'POST',
                body: data
            })
                .then(response => response.json())
                .then(data => {
                if (!data.success)
                    return;
                if (data.data.processedData) {
                    const cryptoPricesTableTbody = document.querySelector('.js-crypto-prices-table-tbody');
                    const bsvCoinTr = document.querySelector('.js-crypto-prices-table-tbody tr:first-of-type');
                    if (cryptoPricesTableTbody && bsvCoinTr) {
                        cryptoPricesTableTbody.innerHTML = bsvCoinTr.outerHTML + data.data.processedData;
                    }
                }
                if (data.data.coinMarketsData) {
                    const coinMarketsData = data.data.coinMarketsData;
                    const marketCap = '$' + (coinMarketsData["data"]["total_market_cap"]["usd"]).toLocaleString('en-US', { maximumFractionDigits: 0 });
                    const totalVolume = '$' + (coinMarketsData["data"]["total_volume"]["usd"]).toLocaleString('en-US', { maximumFractionDigits: 0 });
                    const priceChangePercentage24h = coinMarketsData["data"]["market_cap_change_percentage_24h_usd"];
                    const priceChangePercentage24hFormatted = priceChangePercentage24h.toFixed(1);
                    const totalVolumeTracker = document.querySelector('.js-total-volume-tracker');
                    const marketCapTracker = document.querySelector('.js-market-cap-tracker');
                    const priceChangePercentage24hEl = document.querySelector('.js-price-change-percentage-24h');
                    if (totalVolumeTracker) {
                        totalVolumeTracker.innerHTML = totalVolume;
                    }
                    if (marketCapTracker) {
                        marketCapTracker.innerHTML = marketCap;
                    }
                    if (priceChangePercentage24hEl) {
                        const isPriceUp = priceChangePercentage24h < 0 ? 'is-price-down' : 'is-price-up';
                        const priceUpSvg = '<svg class="crypto-prices__total-change-up-svg" xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none"><path d="M0.2 8.4L5.7 1.06667C6.1 0.533333 6.9 0.533333 7.3 1.06667L12.8 8.4C13.2944 9.05924 12.824 10 12 10H1C0.175955 10 -0.294427 9.05924 0.2 8.4Z" fill="#00B027"/></svg>';
                        const priceDownSvg = '<svg class="crypto-prices__total-change-down-svg svg-down" xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none"><path d="M0.2 8.4L5.7 1.06667C6.1 0.533333 6.9 0.533333 7.3 1.06667L12.8 8.4C13.2944 9.05924 12.824 10 12 10H1C0.175955 10 -0.294427 9.05924 0.2 8.4Z" fill="#B00000"></path></svg>';
                        let htmlContent = priceChangePercentage24hFormatted + (isPriceUp === 'is-price-down' ? priceDownSvg : priceUpSvg);
                        priceChangePercentage24hEl.classList.remove('is-price-up');
                        priceChangePercentage24hEl.classList.remove('is-price-down');
                        priceChangePercentage24hEl.classList.add(isPriceUp);
                        priceChangePercentage24hEl.innerHTML = htmlContent;
                    }
                }
                if (data.data.totalCoinTrackedData) {
                    const totalCoinTrackedData = data.data.totalCoinTrackedData;
                    const totalCoinsTracked = (totalCoinTrackedData["data"]["active_cryptocurrencies"]).toLocaleString('en-US', { maximumFractionDigits: 0 });
                    const totalExchangesTracked = (totalCoinTrackedData["data"]["markets"]).toLocaleString('en-US', { maximumFractionDigits: 0 });
                    const totalCoinsTrackedEl = document.querySelector('.js-total-coins-tracked');
                    const totalExchangesTrackedEl = document.querySelector('.js-total-exchanges-tracked');
                    if (totalCoinsTrackedEl) {
                        totalCoinsTrackedEl.innerHTML = totalCoinsTracked;
                    }
                    if (totalExchangesTrackedEl) {
                        totalExchangesTrackedEl.innerHTML = totalExchangesTracked;
                    }
                }
                if (data.data.totalCategoriesTrackedData) {
                    const totalCategoriesTracked = data.data.totalCategoriesTrackedData.length;
                    const totalCatTracker = document.querySelector('.js-total-cat-tracker');
                    if (totalCatTracker) {
                        totalCatTracker.innerHTML = totalCategoriesTracked;
                    }
                }
            })
                .catch(error => console.error('Error when receiving Coingecodata data:', error));
        };
        const updateMarketCapPageData = () => {
            const delayTime = 5 * 60 * 1000;
            setTimeout(() => {
                fetchCoinGeckoData();
                setInterval(fetchCoinGeckoData, delayTime);
            }, delayTime);
        };
        updateMarketCapPageData();
        let chartRange, chartMain;
        const drawMarketCapChart = (marketCapHistory = []) => {
            if (marketCapHistory) {
                if (!marketCapHistory['market_cap']) {
                    console.error("No market cap history data available");
                    return;
                }
                if (chartMain)
                    chartMain.destroy();
                if (chartRange)
                    chartRange.destroy();
                const data = marketCapHistory['market_cap'].map(d => ({
                    date: new Date(d[0]),
                    value: d[1]
                }));
                const isGrowthPositive = data.length > 0 ? data[data.length - 1].value > data[0].value : false;
                const colorsLine = isGrowthPositive ? '#00B027' : '#B00000';
                const isMobile = window.innerWidth <= 480;
                const optionsMain = {
                    series: [{
                            data: marketCapHistory['market_cap']
                        }],
                    chart: {
                        id: 'main-chart',
                        type: 'area',
                        height: 452,
                        width: '100%',
                        zoom: {
                            enabled: !isMobile,
                            type: 'x',
                            allowMouseWheelZoom: false
                        },
                        toolbar: {
                            show: !isMobile
                        }
                    },
                    annotations: {
                        yaxis: [{
                                y: 30,
                                borderColor: '#999',
                            }],
                        xaxis: [{
                                borderColor: '#999',
                                yAxisIndex: 0,
                            }]
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight',
                        width: 2,
                        colors: [colorsLine]
                    },
                    xaxis: {
                        type: 'datetime',
                        tickAmount: data.length,
                        labels: {
                            rotate: -15,
                            rotateAlways: true,
                            offsetY: -3,
                        },
                    },
                    tooltip: {
                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                            const timestamp = w.globals.seriesX[seriesIndex][dataPointIndex];
                            const marketCap = series[seriesIndex][dataPointIndex];
                            const volume = marketCapHistory['volume'][dataPointIndex][1];
                            const date = new Date(timestamp);
                            const formattedDate = date.toLocaleString('en-US', {
                                month: 'short',
                                day: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                                timeZoneName: 'short'
                            });
                            const formatCurrency = (num) => {
                                return '$' + num.toLocaleString('en-US');
                            };
                            return `
                        <div style="padding: 10px; font-size: 14px; font-family: Arial, sans-serif; background: #fff; border: 1px solid #ddd; border-radius: 5px;">
                            <div style="font-weight: bold; margin-bottom: 5px;">${formattedDate}</div>
                            <div>Market cap: ${formatCurrency(marketCap)}</div>
                            <div>Vol: ${formatCurrency(volume)}</div>
                        </div>
                    `;
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 1,
                            opacityFrom: 0.7,
                            opacityTo: 0.3,
                            colorStops: [
                                { offset: 0, color: colorsLine, opacity: 0.7 },
                                { offset: 100, color: colorsLine, opacity: 0.1 }
                            ]
                        }
                    },
                    yaxis: {
                        opposite: true,
                        labels: {
                            formatter: function (value) {
                                if (value >= 1e12) {
                                    return '$' + (value / 1e12).toFixed(1) + 'T';
                                }
                                else if (value >= 1e9) {
                                    return '$' + (value / 1e9).toFixed(1) + 'B';
                                }
                                else if (value >= 1e6) {
                                    return '$' + (value / 1e6).toFixed(1) + 'M';
                                }
                                else {
                                    return '$' + value.toFixed(0);
                                }
                            }
                        }
                    }
                };
                const optionsRange = {
                    series: [{
                            data: marketCapHistory['market_cap']
                        }],
                    chart: {
                        id: 'range-selector',
                        height: 100,
                        type: 'area',
                        brush: {
                            target: 'main-chart',
                            enabled: true
                        },
                        selection: {
                            enabled: true,
                            xaxis: {
                                min: marketCapHistory['market_cap'][0][0],
                                max: marketCapHistory['market_cap'][marketCapHistory['market_cap'].length - 1][0]
                            }
                        },
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 1
                    },
                    fill: {
                        type: 'solid',
                        opacity: 0.2
                    },
                    xaxis: {
                        type: 'datetime',
                        height: 50,
                        labels: {
                            show: true,
                            rotate: -15,
                            rotateAlways: true,
                            offsetY: -3,
                        }
                    },
                    yaxis: {
                        show: false
                    }
                };
                const cryptoPricesTabPanel = document.querySelector(".js-crypto-prices-tab-panel");
                if (cryptoPricesTabPanel) {
                    cryptoPricesTabPanel.innerHTML = `
                        <div id="main-chart"></div>
                        <div id="range-selector"></div>
                    `;
                }
                chartMain = new ApexCharts(document.querySelector("#main-chart"), optionsMain);
                chartRange = new ApexCharts(document.querySelector("#range-selector"), optionsRange);
                chartMain.render();
                chartRange.render();
                chartMain.zoomX(marketCapHistory['market_cap'][0][0], marketCapHistory['market_cap'][marketCapHistory['market_cap'].length - 1][0]);
                document.addEventListener("click", (event) => {
                    if (event.target.classList.contains("apexcharts-reset-icon")) {
                        drawMarketCapChart(marketCapHistory);
                    }
                });
            }
        };
        if (typeof marketCapHistory !== "undefined") {
            drawMarketCapChart(marketCapHistory);
        }
        const handleMarketCapChartTabs = () => {
            document.querySelectorAll('.js-crypto-chart-tab').forEach(tab => {
                tab.addEventListener('click', function () {
                    document.querySelectorAll('.js-crypto-chart-tab').forEach(el => el.classList.remove('active'));
                    this.classList.add('active');
                    const targetTabName = this.dataset.tab;
                    const data = new FormData();
                    data.append('action', 'get_market_cap_history');
                    data.append('tabName', targetTabName);
                    data.append('nonce', ajax_object.nonce);
                    fetch(ajax_object.ajaxurl, {
                        method: 'POST',
                        body: data
                    })
                        .then(response => response.json())
                        .then(data => {
                        if (!data.success || !data.data.payload)
                            return;
                        drawMarketCapChart(data.data.payload);
                    })
                        .catch(error => console.error('Error when receiving Market cap history data:', error));
                });
            });
        };
        handleMarketCapChartTabs();
        const filterMarketCapTable = () => {
            document.querySelectorAll(".js-table-filter-icon").forEach(icon => {
                icon.addEventListener("click", function () {
                    const columnIndex = this.closest("th").cellIndex;
                    const table = this.closest("table");
                    const tbody = table.querySelector("tbody");
                    const rows = Array.from(tbody.querySelectorAll("tr:not(:first-of-type)"));
                    const tableFilters = document.querySelectorAll('.js-crypto-prices-table th');
                    if (tableFilters) {
                        [...tableFilters].forEach((filterTh, index) => {
                            const actionType = index !== columnIndex ? 'remove' : 'add';
                            filterTh.classList[actionType]('active');
                        });
                    }
                    const isAscending = this.closest("th").getAttribute("data-order") === 'desc';
                    this.closest("th").setAttribute("data-order", isAscending ? "asc" : "desc");
                    rows.sort((rowA, rowB) => {
                        const cellA = rowA.cells[columnIndex].textContent.trim();
                        const cellB = rowB.cells[columnIndex].textContent.trim();
                        const cleanA = cellA.replace(/[^0-9.\-]/g, '');
                        const cleanB = cellB.replace(/[^0-9.\-]/g, '');
                        const numA = parseFloat(cleanA);
                        const numB = parseFloat(cleanB);
                        if (!isNaN(numA) && !isNaN(numB)) {
                            return isAscending ? numA - numB : numB - numA;
                        }
                        else {
                            return isAscending ? cellA.localeCompare(cellB) : cellB.localeCompare(cellA);
                        }
                    });
                    tbody.append(...rows);
                });
            });
        };
        filterMarketCapTable();
        const initAdvertisingSlider = () => {
            if (typeof Swiper === "undefined") {
                console.warn("Swiper is not loaded");
                return;
            }
            const swiper = new Swiper(".js-advertising-slider", {
                slidesPerView: "auto",
                spaceBetween: 0,
                centeredSlides: true,
                speed: 1000,
                loop: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
            });
        };
        initAdvertisingSlider();
        const getUpdatedChart = () => {
            const element = document.querySelector('.js-crypto-chart-tab.active');
            const event = new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
            });
            if (element) {
                element.dispatchEvent(event);
            }
        };
        window.addEventListener("resize", getUpdatedChart);
        const restorePageScrollPosition = () => {
            document.querySelectorAll('.js-crypto-prices-pagination a').forEach(tab => {
                tab.addEventListener('click', function (event) {
                    event.preventDefault();
                    const targetHref = this.getAttribute('href');
                    const targetElement = document.querySelector('.js-crypto-prices-table-wrap');
                    if (targetElement) {
                        const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
                        localStorage.setItem('scrollPosition', targetPosition);
                        window.location.href = targetHref;
                    }
                });
            });
            if (localStorage.getItem('scrollPosition')) {
                let savedScrollPosition = localStorage.getItem('scrollPosition');
                savedScrollPosition = parseInt(savedScrollPosition) - 70;
                window.scrollTo({
                    top: savedScrollPosition,
                    behavior: 'smooth'
                });
                localStorage.removeItem('scrollPosition');
            }
        };
        restorePageScrollPosition();
    });
}
window.document.addEventListener('DOMContentLoaded', ready);
